/* eslint-disable no-restricted-globals */
/* eslint-disable func-names */
/* eslint-disable radix */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
import { Breadcrumbs, Typography } from '@mui/material';
import { ErrorMessage, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
import { Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import moment from 'moment/moment';
import DatePicker from 'react-datepicker';
import ConfirmModal from '../../../components/modal/ConfirmModal';
import ButtonAction from '../../../components/buttons/ButtonAction';
import Input from '../../../components/forms/input';
import InputPrepend from '../../../components/forms/InputPrepend';
import InputPrepend2 from '../../../components/forms/InputPrepend2';
import Select from '../../../components/forms/Select';
import saveIcon from '../../../assets/images/icons/SaveIcon.svg';
import { AddInfaqData, AddStoryImage } from '../../../services/infaq';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
import 'react-datepicker/dist/react-datepicker.css';

const styleActiveBreadcrumb = {
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

const styleInactiveBreadcrumb = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: 'rgba(79, 88, 103, 0.7)',
  textDecoration: 'none',
  fontFamily: 'BlueberrySans-Regular',
};

function DailyNotificationForm(props) {
  const formikRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [quillValue, setQuillValue] = useState('');
  const quillRef = useRef();
  const [donationName, setDonationName] = useState('');
  const [donationImage, setDonationImage] = useState(null);
  const [imageName, setImageName] = useState('Upload File Poster');
  const [donationTarget, setDonationTarget] = useState('');
  const [donationTargetDisplay, setDonationTargetDisplay] = useState('');
  const [duration, setDuration] = useState('0');
  const [status, setStatus] = useState('');
  const [fundraiser, setFundraiser] = useState('');
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailName, setThumbnailName] = useState('Upload Gambar Cerita Program');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [repeat, setRepeat] = useState('');
  const [checkbox, setCheckBox] = useState(false);
  const [modalCloseConfirmation, setShowModalCloseConfirmation] = useState(false);
  const [modalAddConfirmation, setShowModalAddConfirmation] = useState(false);
  const [inputMediaColor, setInputMediaColor] = useState('');
  const [inputThumbnailColor, setInputThumnailColor] = useState('');
  const getDate = moment().format('YYYY-MM-DD');
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [dateRangeFormik, setDateRangeFormik] = useState([null, null]);
  const [isInvalidClassName, setIsInvalidClassName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [filledThumbnail, setFilledThumbnail] = useState(false);

  const initialValues = {
    donationName: donationName || '',
    donationImage: donationImage || null,
    donationTarget: donationTarget || '',
    status: status || '',
    fundraiser: fundraiser || '',
    thumbnail: thumbnail || null,
    startTime: startTime || '',
    endTime: endTime || '',
    repeat: repeat || '',
    dateRangeFormik: [null, null],
    startDate: null,
    endDate: null,
  };

  const validation = Yup.object().shape({
    donationName: Yup.string()
      .required('Nama Donasi Wajib Diisi')
      .max(100, 'Nama Donasi Terlalu Panjang')
      .nullable(),
    status: Yup.string().required('Status Wajib Diisi').nullable(),
    fundraiser: Yup.string().required('Penggalang Dana Wajib Diisi').nullable(),
    startTime: Yup.string().nullable(),
    endTime: Yup.string().nullable().test('is-greater', 'Waktu Selesai Harus Sesuai', function (value) {
      const { startTime } = this.parent;
      return value === undefined || moment(value, 'HH:mm').isSameOrAfter(moment(startTime, 'HH:mm'));
    }),
    // startDate: Yup.string().required('Tanggal Mulai Harus Diisi').nullable(),
    // endDate: Yup.string().required('Tanggal Berakhir Harus Diisi').nullable(),
    dateRangeFormik: Yup.array()
      .required('Tanggal Mulai dan Tanggal Berakhir Harus Diisi')
      .test(
        'is-valid-date-range',
        'Tanggal Mulai dan Tanggal Berakhir Harus Diisi',
        (value) => Array.isArray(value) && value.length === 2 && value.every((date) => date !== null),
      )
      .of(
        Yup.date()
          .nullable()
          .test('is-valid-date', 'Tanggal tidak valid', (date) => date === null || moment(date).isValid()),
      ),
    donationTarget: Yup.string().required('Target Donasi Wajib Diisi'),
    donationImage: Yup.mixed()
      .required('Poster Wajib Diisi')
      .test(
        'fileSize',
        'Gambar maksimal sebesar 1MB',
        (value) => value === null || value.size <= 1000000,
      )
      .test(
        'fileFormat',
        'Unsupported file type',
        (value) => value === null
          || (value
            && ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(
              value.type,
            )),
      ),
    thumbnail: Yup.mixed().when('filledThumbnail', {
      is: true,
      then: (schema) => schema
        .test(
          'fileSize',
          'Gambar Maksimal 1MB',
          (value) => value === null || value.size <= 1000000,
        )
        .test(
          'fileFormat',
          'Unsupported file type',
          (value) => value === null
            || (value
              && ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'].includes(
                value.type,
              )),
        ),
    }),
  });

  const imageHandler = () => {
    // get editor
    const editor = quillRef.current.getEditor();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      try {
        const formData = new FormData();
        formData.append('storyDescriptionImg', file);
        const response = await AddStoryImage(formData);
        const result = await response.text();
        const link = result;
        editor.insertEmbed(editor.getSelection(), 'image', link);
      } catch (err) {
        console.log('upload err:', err);
      }
    };
  };

  // options
  const optionsStatus = [
    { value: true, label: 'Aktif' },
    { value: false, label: 'Tidak Aktif' },
  ];
  const optionsRepeat = [
    { value: 'daily', label: 'Setiap Hari' },
    { value: 'weekly', label: 'Setiap Minggu' },
  ];
  const volunteerOptions = [
    { value: 'Dzikra', label: 'Dzikra' },
    { value: 'Inovasi 360', label: 'Inovasi 360' },
    { value: 'rumah_zakat', label: 'Rumah Zakat' },
  ];
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ script: 'sub' }, { script: 'super' }],
        ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
        ['link', 'image'],
        ['clean'],
      ],
      handlers: {
        image: imageHandler,
      },
    },
  }), []);

  const handleCheckBox = (e) => {
    setCheckBox(e.target.checked);
  };

  const onHideConfirmation = () => {
    setShowModalCloseConfirmation(false);
  };

  const onHideAddConfirmation = () => {
    setShowModalAddConfirmation(false);
  };

  const checkForm = (values) => {
    if (values.donationName !== '' || values.donationImage !== null || values.startDate !== null || values.endDate !== null || values.donationTarget !== '' || values.status !== '' || values.fundraiser !== '' || values.thumbnail !== null || values.startTime !== '' || values.endTime !== '' || values.repeat !== '') {
      setShowModalCloseConfirmation(true);
    } else {
      navigate('/infaq/program');
    }
  };

  const handleAddInfaq = async (values) => {
    // set range date
    try {
      const end = new Date(endDate);
      const start = new Date(startDate);
      const rangeStart = start.toLocaleDateString().split('/');
      const rangeEnd = end.toLocaleDateString().split('/');
      const formatedStartDate = (`${rangeStart[2]}/${rangeStart[0]}/${rangeStart[1]}`);
      const formatedEndDate = (`${rangeEnd[2]}/${rangeEnd[0]}/${rangeEnd[1]}`);
      const formData = new FormData();
      formData.append('programName', values.donationName);
      formData.append('fundraiser', values.fundraiser);
      formData.append('donationTarget', values.donationTarget.replaceAll(',', ''));
      formData.append('endDate', formatedEndDate.replaceAll('-', '/'));
      formData.append('endTime', values.endTime);
      formData.append('startDate', formatedStartDate.replaceAll('-', '/'));
      formData.append('startTime', values.startTime);
      formData.append('media', values.donationImage);
      formData.append('repeat', values.repeat);
      formData.append('status', values.status);
      formData.append('storyThumbnail', values.thumbnail);
      formData.append('storyDescription', quillValue);

      const response = await AddInfaqData(formData);
      if (response.status === 200) {
        localStorage.setItem('isAdded', true);
        navigate('/infaq/program');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="content-container-side">
      <div className="d-flex">
        <p className="content-title">Infaq</p>
      </div>
      <div className="mb-4">
        <Breadcrumbs aria-label="breadcrumb" separator="">
          <Link
            color="inherit"
            to="/infaq/program"
            style={styleActiveBreadcrumb}
          >
            Daftar Infaq
          </Link>
          <Typography style={styleInactiveBreadcrumb}>
            Tambah Program
          </Typography>
        </Breadcrumbs>
      </div>
      <Card className="card-datatable">
        <div className="d-flex">
          <span
            className="material-icons clickable"
            onClick={() => {
              checkForm(formikRef.current.values);
            }}
            onKeyPress={() => navigate('/notification')}
            role="button"
            tabIndex={0}
          >
            arrow_back
          </span>
          <p className="card-datatable-title ms-3">
            Tambah Program
          </p>
        </div>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validation}
          enableReinitialize
          onSubmit={(values, errors, touched) => {
            setShowModalAddConfirmation(true);
          }}
        >
          {(props) => (
            <Form>
              <div className="row">
                <div className="col-lg-6">
                  <Input
                    title="Nama Donasi"
                    name="donationName"
                    type="text"
                    errors={props?.errors}
                    touched={props?.touched}
                    {...props?.getFieldProps('donationName')}
                  />
                </div>
                <div className="col-lg-6">
                  <Input
                    title="Poster"
                    name="donationImage"
                    filename={imageName}
                    inputColor={inputMediaColor}
                    type="file"
                    errors={props?.errors}
                    touched={props?.touched}
                    onChange={(e) => {
                      props.setFieldValue('donationImage', e.target.files[0]);
                      setImageName(e.target.files[0].name);
                      setInputMediaColor('color-input-black');
                    }}
                    accept="image/png, image/jpeg"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label className="form-label">Tanggal</label>
                        <DatePicker
                          selectsRange
                          name="dateRangeFormik"
                          className={`form-input form-control ${isInvalidClassName}`}
                          startDate={startDate}
                          placeholderText="Masukan Tanggal"
                          endDate={endDate}
                          minDate={new Date(getDate)}
                          onChange={(update) => {
                            if (update[0] !== null && update[1] !== null) {
                              props.setFieldValue('dateRangeFormik', update);
                            }
                            setDateRange(update);
                            const start = moment(update[0], 'YYYY-MM-DD');
                            const end = moment(update[1], 'YYYY-MM-DD');
                            const day = moment.duration(end.diff(start)).asDays();
                            setDuration(day);
                          }}
                        />
                        <small><ErrorMessage name="dateRangeFormik" component="div" className="text-danger" /></small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 target-donation">
                  <InputPrepend2
                    label="Target Donasi"
                    title="Rp"
                    name="donationTarget"
                    type="text"
                    width="10%"
                    value={donationTargetDisplay}
                    mb="0"
                    onChange={(e) => {
                      if (!isNaN(e.target.value.replaceAll('.', ''))) {
                        const moneyFormatter = new Intl.NumberFormat();
                        const donationFormat = moneyFormatter.format(e.target.value.replaceAll('.', ''));
                        setDonationTargetDisplay(donationFormat.replaceAll(',', '.'));
                        props.setFieldValue(
                          'donationTarget',
                          e.target.value.replaceAll('.', ''),
                          true,
                        );
                      }
                    }}
                    errors={props?.errors}
                    touched={props?.touched}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 disabled">
                  <InputPrepend
                    label="Durasi"
                    name="duration"
                    title="Hari"
                    mb="0"
                    type="text"
                    disabled
                    value={duration}
                    width="20%"
                  />
                </div>
                <div className="col-lg-6">
                  <Select
                    name="status"
                    title="Status"
                    value={props?.values?.status}
                    errors={props?.errors}
                    touched={props?.touched}
                    isSearchable={false}
                    options={optionsStatus}
                    onChange={(name, value) => {
                      props.setFieldValue('status', value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <Select
                    name="fundraiser"
                    title="Penggalang Dana"
                    value={props?.values?.fundraiser}
                    errors={props?.errors}
                    touched={props?.touched}
                    isSearchable={false}
                    options={volunteerOptions}
                    onChange={(name, value) => {
                      props.setFieldValue('fundraiser', value);
                    }}
                    onBlur={props.setFieldTouched}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 mt-3 card-datatable-title">
                  Cerita Program
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <Input
                    title="Gambar Cerita Program"
                    name="thumbnail"
                    filename={thumbnailName}
                    type="file"
                    inputColor={inputThumbnailColor}
                    errors={props?.errors}
                    touched={props?.touched}
                    onChange={(e) => {
                      props.setFieldValue(
                        'thumbnail',
                        e.target.files[0],
                        true,
                      );
                      setThumbnailName(e.target.files[0].name);
                      setInputThumnailColor('color-input-black');
                      setFilledThumbnail(true);
                    }}
                    accept="image/png, image/jpeg"
                  />
                </div>
                <div className="col-lg-12">
                  <div className="col-lg-12 col-md-12">
                    <div className="col-lg-6 col-md-12 description-label">
                      Deskripsi
                    </div>
                    <ReactQuill
                      theme="snow"
                      modules={modules}
                      onChange={setQuillValue}
                      ref={quillRef}
                    />
                  </div>
                </div>
              </div>
              <div className="switch-container mt-4 d-flex">
                <p className="card-datatable-title">Periode Program</p>
                <label className="switch ms-2 mt-1">
                  <input type="checkbox" onClick={handleCheckBox} />
                  <span className="slider round" />
                </label>
              </div>
              {checkbox ? (
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <Input
                      title="Waktu Mulai"
                      name="startTime"
                      type="time"
                      errors={props?.errors}
                      touched={props?.touched}
                      {...props?.getFieldProps('startTime')}
                    />
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <Input
                      title="Waktu Selesai"
                      name="endTime"
                      type="time"
                      errors={props?.errors}
                      touched={props?.touched}
                      {...props?.getFieldProps('endTime')}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <Select
                      name="repeat"
                      title="Ulangi"
                      value={props?.values?.repeat}
                      errors={props?.errors}
                      touched={props?.touched}
                      isSearchable={false}
                      options={optionsRepeat}
                      onChange={(name, value) => {
                        props.setFieldValue('repeat', value);
                      }}
                      onBlur={props.setFieldTouched}
                    />
                  </div>
                </div>
              ) : ' '}
              <div className="d-flex align-items-center mt-5">
                <div className="ms-auto">
                  <ButtonAction
                    cancelTitle="Batal"
                    confirm={() => {
                      if (startDate === null || endDate === null) {
                        setIsInvalidClassName('is-invalid');
                      } else {
                        setIsInvalidClassName('');
                      }
                    }}
                    confirmTitle="Simpan Data"
                    toggle={() => {
                      checkForm(formikRef.current.values);
                    }}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <ConfirmModal
          show={modalCloseConfirmation}
          message="Apakah anda yakin ingin membatalkan proses tambah program infaq?"
          onHide={onHideConfirmation}
          confirmTitle="Yakin"
          cancelTitle="Batal"
          toggle={onHideConfirmation}
          inlineIcon={saveIcon}
          confirm={() => {
            navigate('/infaq/program');
          }}
        />
        <ConfirmModal
          show={modalAddConfirmation}
          message="Apakah Anda yakin data yang dimasukkan sudah sesuai?"
          onHide={onHideAddConfirmation}
          confirmTitle={
            isLoading ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" />
                Loading...
              </>
            ) : (
              'Ya, lanjutkan'
            )
          }
          cancelTitle="Periksa Kembali"
          toggle={onHideAddConfirmation}
          inlineIcon={saveIcon}
          confirm={() => {
            setIsLoading(true);
            handleAddInfaq(formikRef.current.values);
          }}
          isLoading={isLoading}
        />
      </Card>
    </div>
  );
}

export default DailyNotificationForm;
